import React from 'react'
import FooterLink from './FooterLink'
import DeploymentInfo from './DeploymentInfo'

export default ({ className }) => (
  <footer className={className}>
    <div className="flex flex-col text-center md:flex-row md:flex-left justify-between text-grey pt-8 pb-4 mx-12">
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>
        <FooterLink to="/presse">Presse Material</FooterLink>
        <FooterLink to="/impressum">Impressum</FooterLink>
        <FooterLink to="/datenschutz">Datenschutz</FooterLink>
      </div>
    </div>
    <DeploymentInfo className="text-center py-2" />
  </footer>
)
