import React from 'react'
import { Link } from 'gatsby'

export default ({ name, path }) => {
  return (
    <div className="text-center mt-8 mb-8">
      <Link
        to={path}
        className="border-grey text-grey border-none"
        title={`zurück zur Seite ${name}`}
      >
        {name}
      </Link>
    </div>
  )
}
