import React from 'react'
import { Link } from 'gatsby'

export default props => (
  <li className="m-5 text-center">
    <Link
      to={props.to}
      className="text-black no-underline border-0 font-semibold"
      activeClassName="border-b-2 border-primary"
      partiallyActive={props.partiallyActive}
    >
      {props.children}
    </Link>
  </li>
)
