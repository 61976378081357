import React from 'react'
import NavigationItem from './NavigationItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef()
    this.state = {
      collapseMobileMenu: false,
    }

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
    this.closeMobileMenu = this.closeMobileMenu.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  toggleMobileMenu() {
    if (this.state.collapseMobileMenu) {
      this.closeMobileMenu()
    } else {
      this.openMobileMenu()
    }
  }

  openMobileMenu() {
    disableBodyScroll(this.menu.current)
    this.setState(() => ({
      collapseMobileMenu: true,
    }))
  }
  closeMobileMenu() {
    enableBodyScroll(this.menu.current)
    this.setState(() => ({
      collapseMobileMenu: false,
    }))
  }

  handleClick() {
    enableBodyScroll(this.menu.current)
  }

  render() {
    return (
      <nav
        className={
          this.props.className +
          (this.state.collapseMobileMenu &&
            ' bg-white-dark fixed pin z-50 md:relative') +
          ' flex flex-col md:flex-row md:justify-end md:bg-transparent'
        }
      >
        <button
          data-label="burger-button"
          type="button"
          className={'md:hidden m-2 p-3 font-semibold text-black self-end'}
          onClick={this.toggleMobileMenu}
        >
          {this.state.collapseMobileMenu ? (
            <>
              <FontAwesomeIcon icon="times" className="mr-2 pt-1" />
              <span>schließen</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="bars" className="mr-2" />
              <span>MENU</span>
            </>
          )}
        </button>

        <ul
          data-label="menu"
          ref={this.menu}
          className={
            (!this.state.collapseMobileMenu && ' hidden') +
            ' navigation list-reset text-2xl md:text-base md:flex overflow-auto'
          }
          onClick={this.handleClick}
        >
          <NavigationItem key="startseite" to="/">
            Startseite
          </NavigationItem>
          {this.props.items.map(name => {
            return (
              <NavigationItem
                key={name}
                to={`/${name.toLowerCase()}/`}
                partiallyActive
              >
                {name}
              </NavigationItem>
            )
          })}
        </ul>
      </nav>
    )
  }
}

export default Navigation
