import React from 'react'
import textPageSectionStyles from './MvTextPageSection.module.css'

export default ({ title, anchor, children, fullWidth }) => {
  const widthCss = fullWidth ? '' : 'max-w-lg mx-auto'

  function getAnchorName(title) {
    const anchorName = anchor ? anchor : title
    return encodeURI(anchorName.toLowerCase())
  }

  return (
    <div className={widthCss + ' ' + textPageSectionStyles.textPageSection}>
      {title && <a name={getAnchorName(title)} />}
      {title && <h2 className="text-center mb-4 mt-20">{title}</h2>}
      {children}
    </div>
  )
}
