import React from 'react'
import MvHeadline from './MvHeadline'
import MvTextPageParentLink from './MvTextPageParentLink'

export default ({
  title,
  parent,
  sections,
  children,
  dataLabel,
  className,
}) => (
  <>
    {parent && <MvTextPageParentLink name={parent.name} path={parent.path} />}
    {title && <MvHeadline>{title}</MvHeadline>}

    {sections && (
      <ul className="list-reset flex flex-col items-center md:flex-row justify-center -mt-10">
        {sections.map(section => (
          <li className="mx-4 my-2" key={section}>
            <a
              href={'#' + section.toLowerCase()}
              className="border-grey text-grey"
            >
              {section}
            </a>
          </li>
        ))}
      </ul>
    )}

    <article data-label={dataLabel} className={className}>
      {children}
    </article>
  </>
)
